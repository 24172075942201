import { useEffect, useState } from 'react';

import { LAYOUT_BREAKPOINTS } from 'utils/constants';

interface UseScreenRecognitionHook {
  (): { isMobile: boolean; isTablet: boolean; isSmallTablet: boolean };
}

const useScreenRecognition: UseScreenRecognitionHook = () => {
  const [screen, setScreen] = useState({ isMobile: false, isTablet: false, isSmallTablet: false });

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;

      setScreen({
        isMobile: width < LAYOUT_BREAKPOINTS.lg,
        isTablet: width > LAYOUT_BREAKPOINTS.smd && width < LAYOUT_BREAKPOINTS.lg,
        isSmallTablet: width < LAYOUT_BREAKPOINTS.md,
      });
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return screen;
};

export default useScreenRecognition;
